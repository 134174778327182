import React, { Fragment, useContext } from 'react';
import context from '../context';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import Link from "../components/link";
import { PhoneOutlined, WhatsAppOutlined, EnvironmentOutlined, FacebookOutlined, InstagramOutlined, TwitterOutlined, UpOutlined, MailOutlined } from '@ant-design/icons';

import Logo from './logo';
import { NavLink } from '../styled-components';
import Map from '../components/map';
import footerImg from '../images/footer.jpg';

const Footer = styled.footer`
  padding: 1rem 0 0;
  background-image: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url("${footerImg}");
  background-size: cover;
  background-position: center;
  min-height: 50vh;
  display: flex;
  align-items: center;
  //background-color: rgba(0, 0, 0, .070);
`
const FooterText = styled.p`
  color: #fff;
  font-size: 1rem;
  text-align: justify;
`
const InfoList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 1rem;
`
const InfoItem = styled.li`
  color: #fff;
  font-size: 1rem;
  margin-bottom: .5rem;
  display: flex;
  align-items: center;
  span{
    margin-right: .3rem;
  }
`
const InfoLink = styled.a`
  color: #fff !important;
  &:hover{
    text-decoration: underline !important;
  }
`
const NavCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const NavList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  //display: flex;
  margin-bottom: 1rem;
  margin-top: 6rem;
`
const NavItem = styled.li`
font-size: 1rem;
margin-bottom: .5rem;
  &::before{
    content: "‣";
    color: #fff;
  }
`
const NavLinkCustom = styled(NavLink)`
  margin-left: .3rem;
  color: #fff;
  &:hover{
    font-weight: bold;
  }
`
const SocialCont = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 1rem;
  margin-bottom: 1rem;
  @media(min-width: 992px){
    margin-bottom: 1rem;
  }
`
const SocialItem = styled.span`
  color: ${props => props.icon ? "#fff" : "gray"};
  font-size: 1rem;
`
const SocialLink = styled.a`
  color: #fff;
  margin-left: .5rem;
  &:hover{
    color: ${props => props.theme.primaryColor};
  }
`
const BackTop = styled.button`
  display: flex;
  justify-content: center;
  border: none;
  cursor: pointer;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: ${props => props.theme.primaryColor};
  margin-bottom: 1rem;
  transition: 250ms ease;
  color: #fff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, .12),
              0px 2px 2px rgba(0, 0, 0, .12),
              0px 4px 4px rgba(0, 0, 0, .12),
              0px 8px 8px rgba(0, 0, 0, .12);
  &:hover{
    filter: brightness(1.1);
  };
  &:active{
    box-shadow: none;
  }
`

const CopyrightCont = styled.div`
  padding: .3rem 0;
  color: #fff;
  background-color: ${props => props.theme.primaryColor};
  font-size: .8rem;
  //margin-top: 1rem;
`
const CopyrightInnerCont = styled.div`
  display: flex;
  justify-content: space-between;
`
const DevelopedBy = styled.a`
  color: #fff !important;
  transition: 250ms ease;
  font-weight: bold;
  &:hover{
    text-decoration: underline !important;
  }
`

export default ()=> {
  const state = useContext(context);

  return(
    <Fragment>
    <Footer>
      <Container>
        <Row className="align-items-center">
          <Col xs={{ span: 6, order: 6 }} md={{ span: 6, order: 0 }} lg={4}>
            <Link to="/">
              <Logo dark />
            </Link>
            <InfoItem>
            Con más de 17 años de experiencia en el mercado inmobiliario, en Ambiado Propiedades nos enorgullecemos de ser tu socio confiable en cada paso de tu viaje hacia la propiedad ideal. Hemos sido testigos y participantes activos en la realización de sueños y la construcción de hogares felices.            </InfoItem>               
             <InfoList>
           
              {/*{
                state.email && (
                  <InfoItem>
                    <MailOutlined />
                    <InfoLink title="Enviar un email" href={`mailto:${state.email}`}>
                      {state.email}
                    </InfoLink>
                  </InfoItem>                  
                )
              }*/}
           
              {
                state.movil && (
                  <InfoItem>
                    <WhatsAppOutlined />
                    <InfoLink title="Enviar WhatsApp" rel="noopener" target="_blank" href={`https://api.whatsapp.com/send?phone=${state.movil.replace(/\s/g,'')}&text=Hola,%20estoy%20visitando%20su%20sitio%20Web%20y%20quisiera%20comunicarme%20con%20uestedes.`}>
                      {state.movil}
                    </InfoLink>
                  </InfoItem>                      
                )
              }          
            </InfoList> 
          </Col>
          <Col xs={12} md={6} lg={5}>
            <NavCont>
              <NavList>
                <NavItem>
                  <Link to="/about">
                    <NavLinkCustom light>
                      Nosotros
                    </NavLinkCustom>
                  </Link>            
                </NavItem>                  
                <NavItem>
                  <Link to="/properties">
                    <NavLinkCustom light>
                      Propiedades
                    </NavLinkCustom>
                  </Link>            
                </NavItem>
                <NavItem>
                  <Link to="/agents">
                    <NavLinkCustom light>
                      Agentes
                    </NavLinkCustom>
                  </Link>            
                </NavItem>   
                <NavItem>
                  <Link to="/how-much">
                    <NavLinkCustom light>
                      ¿Cuanto vale tu propiedad?
                    </NavLinkCustom>
                  </Link>            
                </NavItem>                                             
                <NavItem>
                  <Link to="/contact">
                    <NavLinkCustom light>
                      Contacto
                    </NavLinkCustom>
                  </Link>                                    
                </NavItem>
                {/*<NavItem>
                  <Link href={`http://app.clasihome.com/login?logo=https://tradehouse.netlify.app/${state.logo}&primaryColor=${state.primaryColor.substring(1)}`} target="_blank" rel="noopener">
                    <NavLinkCustom light>
                      Iniciar sesión
                    </NavLinkCustom>
                  </Link>            
          </NavItem> */}   
              </NavList>            
              <FooterText>
                
              </FooterText>
            </NavCont>
          </Col>                          
          <Col xs={{ span: 6, order: 12 }} md={12} lg={3}>
            <NavCont className="align-items-end">
              <BackTop onClick={()=> window.scrollTo(0, 0)} href="#top">
                <UpOutlined />
              </BackTop>                        
              <SocialCont>
                <SocialItem icon>Síguenos en:</SocialItem>
                <SocialLink href={state.facebook} rel="noopener" target="_blank">
                  <FacebookOutlined />
                </SocialLink>
                <SocialLink icon href={state.facebook} rel="noopener" target="_blank">
                  <InstagramOutlined />
                </SocialLink>
                {/* <SocialLink icon href={state.facebook} rel="noopener" target="_blank">
                  <TwitterOutlined />
                </SocialLink>                                     */}
              </SocialCont>  
              <InfoItem>
                <WhatsAppOutlined />
                <InfoLink title="Enviar WhatsApp" rel="noopener" target="_blank" href={`https://api.whatsapp.com/send?phone=${state.phone.replace(/\s/g,'')}&text=Hola,%20estoy%20visitando%20su%20sitio%20Web%20y%20quisiera%20comunicarme%20con%20ustedes.`}>
                  {state.phone}
                </InfoLink>
              </InfoItem> 
              
              {
                state.phone && (
                  <InfoItem>
                    <PhoneOutlined />
                    <InfoLink title="Llamar" href={`tel:${state.phone.replace(/\s/g,'')}`}>
                      {state.phone}
                    </InfoLink>
                  </InfoItem>                
                )
              }                               
              <InfoItem>
                <MailOutlined />
                <InfoLink title="Enviar Mail" href={`mailto:propiedades@ambiado.cl`}>
                propiedades@ambiado.cl
                </InfoLink>
              </InfoItem>       
              {
                state.address && (
                  <InfoItem>
                  <EnvironmentOutlined />
                  <InfoLink href={`https://www.google.com/maps/place/Coyancura+2270,+of+801,+7510124+Providencia,+Regi%C3%B3n+Metropolitana/@-33.421884,-70.6094289,16.19z/data=!4m6!3m5!1s0x9662cf6f4a879c0b:0x9d63cb0ca632f2f4!8m2!3d-33.4230288!4d-70.6083312!16s%2Fg%2F11n12rh3r_?entry=ttu`}>
{state.address}</InfoLink>
                  
                </InfoItem>                 
                )
              }
              {/*{
                state.email && (
                  <InfoItem>
                    <MailOutlined />
                    <InfoLink title="Enviar un email" href={`mailto:${state.email}`}>
                      {state.email}
                    </InfoLink>
                  </InfoItem>                  
                )
              }*/}
                                                 
            </NavCont>
          </Col>
        </Row>
      </Container>
    </Footer>
    <CopyrightCont>
        <Container>
          <CopyrightInnerCont>
            <span>{new Date().getFullYear()} © Todos los derechos reservados</span>
            <div>
              Desarrollado por <DevelopedBy href="https://clasihome.com" rel="noopener" target="_blank">Clasihome</DevelopedBy>
            </div>
          </CopyrightInnerCont>
        </Container>
      </CopyrightCont>    
    </Fragment>
  )
}