import React, { useContext } from 'react';
import AniLink from "gatsby-plugin-transition-link/AniLink";
import context from '../context';

export default (props)=> {
  const state = useContext(context);

  return(
    <AniLink paintDrip hex={state.primaryColor} to={props.to} duration={.5} {...props}>
      {props.children}
    </AniLink>    
  )
}
